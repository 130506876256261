<template>

    <v-dialog
      v-model="dialog"
      width="900"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          dark
          v-on="on"
        >
          Selecionar Modelo
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline green white--text"
          primary-title
        >
          Selecionar Modelo de MTR
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-layout>
              <v-flex xs12>
                <v-autocomplete
                  v-model="mtrModelSelected"
                  :items="mtrModels"
                  item-text="name"
                  return-object
                  no-data-text="Nenhum modelo encontrado"
                  label="Buscar por nome ou descrição"
                  prepend-icon="mdi-feature-search-outline"
                  max-width="600">

                  <template
                    v-slot:item="data">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{data.item.name}} - {{data.item.description}}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs12>
                <mtr-person-info
                  personType="Transportador"
                  :person="mtrModelSelected.transporter"
                />
                <mtr-person-info
                  personType="Destinador"
                  :person="mtrModelSelected.receiver"
                />
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs12>
                <v-card
                  color="grey lighten-4"
                  class="item mb-3"
                  v-for="(mtrResidue, index) in mtrModelSelected.residues"
                  :key="index"
                >
                  <v-toolbar color="white" flat>
                    <v-chip
                      class="mr-3"
                      label
                      small
                    >
                      {{ mtrResidue.residue.code }}
                    </v-chip>
                    <h2 class="subheading">{{ mtrResidue.residue.name }}</h2>

                    <v-spacer />

                    <span class="subheading grey--text">{{ mtrResidue.physical_state }}</span>

                  </v-toolbar>

                  <v-divider light />

                  <v-card-text class="grey--text text--darken-2">
                    <v-layout row justify-space-around>
                      <v-flex>
                        Classe:
                        <strong>{{ mtrResidue.residue_class }}</strong>
                      </v-flex>
                      <v-flex>
                        Acondicionamento:
                        <strong>{{ mtrResidue.packaging }}</strong>
                      </v-flex>
                      <v-flex>
                        Tecnologia:
                        <strong>{{ mtrResidue.disposal_technology }}</strong>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>

          </v-container>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn

            flat
            @click="dialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            color="primary"
            flat
            @click="selectMtrModel()"
          >
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script>
import { isEmpty } from 'lodash';
import MtrPersonInfo from '@/components/mtr/MtrPersonInfo.vue';
import mtrModelService from '@/services/mtr-model-service';

export default {
  name: 'SelectMtrModelDialog',
  async mounted() {
    const response = await mtrModelService.all();
    this.mtrModels = response.mtr_models;
  },
  components: {
    MtrPersonInfo,
  },
  props: {
  },
  data() {
    return {
      mtrModels: [],
      dialog: false,
      mtrModelSelected: {},
    };
  },
  methods: {
    selectMtrModel() {
      this.$emit('mtrModalSelected', this.mtrModelSelected);
      this.dialog = false;
    },
  },
  computed: {
  },
};
</script>
