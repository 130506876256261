<template>
  <v-form class="mb-4" ref="form" :value="valid">
    <v-container grid-list-lg>

      <mtr-person-info
        personType="Gerador"
        :person="this.$store.getters['auth/currentUser'].person"
      />

      <select-mtr-model-dialog
        @mtrModalSelected="mtrModalSelected"/>

      <v-layout>
        <v-flex md6>
          <h3 class="mb-2">Tem armazenamento temporário?</h3>
          <v-radio-group
            v-model="tempStorageChoice"
            row
          >
            <v-radio label="Não" :value='false'/>
            <v-radio label="Sim" :value='true'/>
          </v-radio-group>

          <MtrPersonForm
            v-if="tempStorageChoice"
            title="Informações de armazenamento temporário"
            profile="temporary_storage"
            :person.sync="tempStorager"
            :description="`Caso utilize armazenamento temporário,
              informar quem é o armazenador.`"
            :temp-storager="true"
          />
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex md6>
          <h3 class="mb-2">Transportador</h3>

          <MtrPersonForm
            title="Informações do transportador"
            :person.sync="transporter"
            :transportedAt.sync="transportedAt"
            :transportedBy.sync="transportedBy"
            :transportLicensePlate.sync="transportLicensePlate"
            profile='transporter'
          />
        </v-flex>

        <v-flex md6>
          <h3 class="mb-2">Destinador</h3>

          <MtrPersonForm
            title="Informações do destinador"
            :person.sync="receiver"
            profile='receiver'
          />
        </v-flex>
      </v-layout>

      <v-divider light my-4/>

      <v-layout justify-center>
        <v-btn
          @click="rewindStep()"
          flat
        >
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          @click.stop="saveStep()"
          :disabled="hasEmpty || tempStorageIsRequired"
        >
          Continuar
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import validationRules from '@/mixins/validation-rules';
import MtrPersonInfo from '@/components/mtr/MtrPersonInfo.vue';
import MtrPersonForm from '@/components/mtr/MtrPersonForm.vue';
import SelectMtrModelDialog from '@/components/mtr/SelectMtrModelDialog.vue';
import { isEmpty } from 'lodash';

export default {
  name: 'mtr-transport-and-destination',
  components: {
    MtrPersonInfo,
    MtrPersonForm,
    SelectMtrModelDialog,
  },
  props: {
    mtr: {
      default: {},
    },
  },
  data() {
    return {
      tempStorageChoice: false,
      tempStorager: {},
      transporter: {},
      receiver: {},
      transportedAt: null,
      transportedBy: '',
      transportLicensePlate: '',
      valid: false,
      validationRules,
      isEmpty,
    };
  },
  methods: {
    onFilePick() {
      this.$refs.fileInput.click();
    },
    mtrModalSelected(payload) {
      this.transporter = payload.transporter;
      this.receiver = payload.receiver;
      this.$emit('residuesFromModel', payload.residues);
    },
    rewindStep() {
      this.transportAndDestionation = {};
      this.$emit('rewindStep');
    },
    saveStep() {
      if (this.$refs.form.validate()) {
        this.$emit('saveStep', {
          transporter: this.transporter,
          transporterId: this.transporter.id,
          tempStorager: this.tempStorager,
          tempStoragerId: this.tempStorager.id,
          hasTempStorager: !_.isEmpty(this.tempStorager),
          receiver: this.receiver,
          receiverId: this.receiver.id,
          transportedAt: this.transportedAt,
          transportedBy: this.transportedBy,
          transportLicensePlate: this.transportLicensePlate,
        });
      }
    },
  },
  computed: {
    hasEmpty() {
      return _.isEmpty(this.transporter)
        || _.isEmpty(this.receiver);
    },
    tempStorageIsRequired() {
      return this.tempStorageChoice === 'yes-temp-storage'
        && _.isEmpty(this.tempStorager);
    },
  },
  watch: {
    mtr(val) {
      if (!isEmpty(val)) {
        this.tempStorager = val.tempStorager;
        this.transporter = val.transporter;
        this.receiver = val.receiver;
        this.transportedAt = val.transportedAt;
        this.transportedBy = val.transportedBy;
        this.transportLicensePlate = val.transportLicensePlate;
      }
    },
  },
};
</script>

<style>
  .send-document{
    border: dashed 2px #a3a3a3 !important;
    border-radius: 5px;
    background-color: #F5F5F5 !important;
    cursor: pointer;
  }

  .btn-delete-file{
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px;
    cursor: pointer;
  }
</style>
