<template>
  <v-container>
    <v-layout
      class="residue-list"
      align-center
      column
    >
      <div>
        <p
          class="grey--text"
          v-if="residues.length === 0"
        >
          Selecione os resíduos deste MTR
        </p>
        <p
          class="grey--text"
          v-if="residues.length > 0"
        >
          <strong>{{ residues.length }} resíduos adicionados.</strong>
          Clique no item para editar ou remover.
        </p>
      </div>

      <v-card
        color="grey lighten-4"
        class="item mb-3"
        v-for="(mtrResidue, index) in residues"
        :key="index"
      >
        <v-toolbar color="white" flat>
          <v-chip
            class="mr-3"
            label
            small
          >
            {{ mtrResidue.residue.code }}
          </v-chip>
          <h2 class="subheading">{{ mtrResidue.residue.name }}</h2>

          <v-spacer />

          <span class="subheading grey--text">{{ mtrResidue.physicalState }}</span>

          <v-menu offset-y>
            <v-btn
              slot="activator"
              fab
              flat
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>

            <v-list>
              <v-list-tile @click="editItem(index, mtrResidue)">
                <v-list-tile-title>Editar resíduo</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="deleteItem(index)">
                <v-list-tile-title>Remover resíduo</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-divider light />

        <v-card-text class="grey--text text--darken-2">
          <v-layout row justify-space-around>
            <v-flex>
              Classe:
              <strong>{{ mtrResidue.residueClass }}</strong>
            </v-flex>
            <v-flex>
              Quantidade Indicado:
              <strong>{{ mtrResidue.quantity }} ({{ mtrResidue.measureUnity }})</strong>
            </v-flex>
            <v-flex>
              Acondicionamento:
              <strong>{{ mtrResidue.packaging }}</strong>
            </v-flex>
            <v-flex>
              Tecnologia:
              <strong>{{ mtrResidue.disposalTechnology }}</strong>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>

      <v-dialog v-model="dialog" max-width="700px" persistent>
        <v-btn
          color="primary"
          slot="activator"
          flat
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>
          Adicionar resíduo
        </v-btn>

        <v-form ref="form" lazy-validation v-model="valid">
          <v-card>
            <v-toolbar color="success" dark flat>
              <span class="headline">{{ dialogTitle }}</span>
              <v-spacer />
              <v-btn flat icon @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-autocomplete
                      ref="residueInput"
                      v-model="editedItem.residue"
                      :items="fetchedResidues"
                      item-text="name"
                      return-object
                      :loading="isFetchingResidues"
                      no-data-text="Nenhum resíduo encontrado"
                      :hide-no-data="!residuesSearch || isUserTyping"
                      no-filter
                      :search-input.sync="residuesSearch"
                      label="Buscar resíduo por: Código/Palavra"
                      :rules="[rules.required]"
                      prepend-icon="mdi-feature-search-outline"
                      :menu-props="{maxWidth:'600'}"
                      @input="onChangeSelectedResidue()">
                      <template v-slot:append-outer>
                        <v-tooltip
                          top
                          max-width="300">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              mdi-information-outline
                            </v-icon>
                          </template>

                          <span>
                            Listagem de Código e descrição dos resíduos de acordo
                             com a Lista Brasileira de Resíduos Sólidos do IBAMA
                            (Instrução Normativa Nº 13, de 18 de dezembro de 2012)
                          </span>
                          <!-- TODO: Aplicar texto legal para resíduos -->
                        </v-tooltip>
                      </template>

                      <template
                        v-slot:item="data">
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{data.item.code}} - {{data.item.name}}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="editedItem.quantity"
                      v-money="money"
                      label="Quantidade"
                      ref="quantityField"
                      :rules="[rules.required, rules.number, rules.minValue]"
                      min="0"
                    />
                  </v-flex>

                  <v-flex xs12 md4>
                    <v-select
                      :items="measureUnityNames"
                      v-model="editedItem.measureUnity"
                      label="Unidade"
                      :rules="[rules.required]">
                    </v-select>
                  </v-flex>

                  <v-flex xs12 md4 v-if="hasDensity">
                    <v-text-field type="number"
                      v-model.number="editedItem.density"
                      label="Densidade"
                      :rules="[rules.required, rules.number]"
                      :suffix="densitySufix(editedItem.measureUnity)">
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-select
                      :items="classes"
                      v-model="editedItem.residueClass"
                      label="Classes"
                      :rules="[rules.required]"
                      :placeholder="classFieldPlaceholder"
                      :disabled="isEmpty(editedItem.residue)"
                    />
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-select
                      :items="physicalStates"
                      item-text="name"
                      item-id="id"
                      v-model="editedItem.physicalState"
                      label="Estado físico"
                      :rules="[rules.required]"
                    />
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-select
                      :items="packagings"
                      v-model="editedItem.packaging"
                      label="Acondicionamento"
                      :disabled="!editedItem.physicalState"
                      :placeholder="hasPhysicalStatePlaceHolder"
                      :rules="[rules.required]"
                    />
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-select
                      :items="technologiesNames"
                      v-model="editedItem.disposalTechnology"
                      label="Tecnologia"
                      :rules="[rules.required]"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="grey"
                @click.native="close"
                flat
              >
                  Cancelar
                </v-btn>
              <v-btn
                color="primary"
                @click.native="save"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-layout>

    <v-divider class="my-3" light />

    <v-layout justify-center>
      <v-btn
        @click="rewindStep()"
        flat
      >
        Voltar
      </v-btn>

      <v-btn
        color="primary"
        @click.stop="saveStep()"
        :disabled="hasEmpty"
      >
        Continuar
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import validationRules from '@/mixins/validation-rules';
import { get, find, isEmpty, map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { VMoney } from 'v-money';
import mtrService from '@/services/mtr-service';

export default {
  directives: { money: VMoney },
  name: 'MtrResidueStep',
  props: {
    residuesFromModel: {
      default: [],
    },
    mtr: {
      default: {},
    },
  },
  data() {
    return {
      classes: [],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        residue: {},
        physicalState: '',
        residueClass: '',
        packaging: '',
        quantity: '',
        measureUnity: '',
        disposalTechnology: '',
      },
      fetchedResidues: [],
      formData: {},
      isEmpty,
      isFetchingResidues: false,
      isUserTyping: false,
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false,
      },
      residues: [],
      residuesSearch: null,
      rules: {
        ...validationRules,
        minValue: v => parseFloat(v.replace('.', '').replace(',', '.')) >= 0 || 'A quantidade não pode ser menor que zero',
      },
      valid: true,
    };
  },
  beforeMount() {
    this.fetchMeasureUnities();
    this.fetchPhysicalStates();
    this.fetchPackagings();
    this.fetchTechnologies();
  },
  methods: {
    ...mapActions({
      fetchMeasureUnities: 'mtr/fetchMeasureUnities',
      fetchPhysicalStates: 'mtr/fetchPhysicalStates',
      fetchPackagings: 'mtr/fetchPackagings',
      fetchTechnologies: 'mtr/fetchTechnologies',
    }),

    editItem(index, mtrResidue) {
      const parsedQuantity = mtrResidue.quantity ? parseFloat(mtrResidue.quantity.replace('.', '').replace(',', '.')).toFixed(2) : 0;
      this.editedIndex = index;
      this.editedItem = { ...mtrResidue };
      this.dialog = true;
      this.residuesSearch = null;
      this.fetchedResidues = [this.editedItem.residue];
      this.$refs.quantityField.$el.getElementsByTagName('input')[0].value = parsedQuantity;
      this.getEditedItemCompleteInfo();
    },

    onChangeSelectedResidue() {
      this.editedItem = {
        ...this.editedItem,
        physicalState: '',
        residueClass: '',
        measureUnity: '',
        packaging: '',
        disposalTechnology: '',
      };
      if (!isEmpty(this.editItem.residue)) {
        this.getEditedItemCompleteInfo();
      }
      this.$refs.residueInput.blur();
    },

    getEditedItemCompleteInfo() {
      const vm = this;
      mtrService.residuesList(this.editedItem.residue.code)
        .then((residues) => {
          vm.fetchedResidues = residues.data;
          const completeResidueInfo = find(vm.fetchedResidues, ['code', vm.editedItem.residue.code]);
          vm.editedItem.residue = {
            ...vm.editedItem.residue,
            residue_classes: completeResidueInfo.residue_classes,
          };
          vm.setResidueClasses(vm.editedItem.residue);
        });
    },

    deleteItem(index) {
      const removeItem = window.confirm('Você quer remover este item?'); // eslint-disable-line no-alert
      if (!isEmpty(this.mtr)) {
        this.removeResidueRequest(this.residues[index]);
      }
      if (removeItem) this.residues.splice(index, 1);
    },

    close() {
      this.resetEditedItem();
      this.isFetchingResidues = false;
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.residuesSearch = null;
      this.fetchedResidues = [];
    },

    resetEditedItem() {
      this.editedItem = {
        residue: {},
        disposalTechnology: '',
        physicalState: '',
        residueClass: '',
        packaging: '',
        quantity: '',
        measureUnity: '',
      };
      this.$refs.quantityField.$el.getElementsByTagName('input')[0].value = '0';
    },

    save() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      if (this.editedIndex > -1) {
        Object.assign(this.residues[this.editedIndex], this.editedItem);
        if (!isEmpty(this.mtr)) {
          this.editResidueRequest({ ...this.editedItem, residue: this.editedItem.residue.id });
        }
      } else {
        this.residues.push({ ...this.editedItem });
        if (!isEmpty(this.mtr)) {
          this.addResidueRequest({ ...this.editedItem, residue: this.editedItem.residue.id });
        }
      }
      this.close();
      return true;
    },

    densitySufix(unit) {
      const densitySufixPerUnit = {
        'Metro Cúbico': 't/m³',
        Litro: 'g/cm³',
      };
      return densitySufixPerUnit[unit] || '';
    },

    fetchResidues(val) {
      if (val === get(this.editedItem, 'residue.description')) return;
      this.isFetchingResidues = true;

      mtrService.residuesList(val)
        .then(({ data }) => {
          this.fetchedResidues = data;
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.isFetchingResidues = false;
        });
    },

    setResidueClasses(residue) {
      this.classes = !isEmpty(residue) && !isEmpty(residue.residue_classes) ?
        residue.residue_classes.map(cl => cl.name) : [];
    },

    rewindStep() {
      this.infoData = {};
      this.$emit('rewindStep');
    },

    saveStep() {
      this.$emit('saveStep', { residues: this.residues });
    },

    addResidueRequest(residue) {
      mtrService.addNewResidue(residue, this.mtr.id);
    },

    editResidueRequest(residue) {
      mtrService.editResidue(residue, this.mtr.id);
    },

    removeResidueRequest(residue) {
      mtrService.removeResidue(residue, this.mtr.id);
    },
  },
  computed: {
    ...mapGetters({
      measureUnities: 'mtr/measureUnities',
      physicalStates: 'mtr/physicalStates',
      technologies: 'mtr/technologies',
    }),

    measureUnityNames() {
      if (this.editedItem.residue && this.editedItem.residue.code === '200121(*)') {
        return ['Unidade'];
      }

      return this.measureUnities.filter(unity => unity.name !== 'Unidade').map(unity => unity.name);
    },

    technologiesNames() {
      return this.technologies.map(state => state.name);
    },

    dialogTitle() {
      return this.editedIndex === -1 ? 'Novo resíduo' : 'Editar resíduo';
    },

    hasPhysicalStatePlaceHolder() {
      return !this.editedItem.physicalState ? 'Selecione um estado físico' : '';
    },

    classFieldPlaceholder() {
      return !this.editedItem.residue ? 'Selecione um resíduo' : '';
    },

    packagings() {
      const { physicalState } = this.editedItem;

      if (!physicalState) {
        return [];
      }

      const physicalStateSelected = this.physicalStates
        .find(state => state.name === physicalState);

      if (!physicalStateSelected) {
        return [];
      }

      return physicalStateSelected.packagings.map(p => p.name);
    },

    hasDensity() {
      const measureUnitiesWithDensity = ['Metro Cúbico', 'Litro'];
      const { measureUnity } = this.editedItem;
      return measureUnity && measureUnitiesWithDensity.includes(measureUnity);
    },

    hasEmpty() {
      return this.residues.length === 0;
    },
  },
  watch: {
    residuesFromModel(val) {
      if (val.length > 0) {
        this.residues = map(val, residue => ({
          residue: residue.residue,
          physicalState: residue.physical_state,
          residueClass: residue.residue_class,
          packaging: residue.packaging,
          disposalTechnology: residue.disposal_technology,
        }));
      }
    },
    residuesSearch(val) {
      if (!val) return;
      clearTimeout(this.timeout);
      this.isUserTyping = true;
      this.timeout = setTimeout(() => {
        this.isUserTyping = false;
        this.fetchResidues(val);
      }, 500);
    },
    mtr(val) {
      if (!isEmpty(val)) {
        this.residues = val.residues;
      }
    },
    editedItem(item) {
      this.setResidueClasses(item.residue);
    },
  },
};
</script>

<style lang="scss">
.residue-list {
  > .item {
    width: 100%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  }
}
</style>
