<template>
  <in-layout>
    <section slot="content">
      <v-stepper vertical v-model="activeStep">
        <!-- step 1 -->
        <v-stepper-step
          step="1"
          :complete="activeStep > 1"
          :color="activeStep > 1 ? 'success' : 'primary'"
        >
          Transporte e Destino
        </v-stepper-step>

        <v-stepper-content step="1">
          <MtrTransportAndDestinationStep
            @rewindStep="backStep"
            @saveStep="nextStep"
            @residuesFromModel="setResiduesFromModel"
            :mtr="mtr"
          />
        </v-stepper-content>
        <!-- /step 1 -->

        <!-- step 2 -->
        <v-stepper-step
          step="2"
          :complete="activeStep > 2"
          :color="activeStep > 2 ? 'success' : 'primary'"
        >
          Resíduos
        </v-stepper-step>

        <v-stepper-content step="2">
          <MtrResidueStep
            @rewindStep="backStep"
            @saveStep="nextStep"
            :mtr="mtr"
            :residuesFromModel="residuesFromModel"
          />
        </v-stepper-content>
        <!-- /step 2 -->

        <!-- step 3 -->
        <v-stepper-step
          step="3"
          :complete="activeStep > 3"
          :color="activeStep > 3 ? 'success' : 'primary'"
        >
          Informções Adicionais
        </v-stepper-step>

        <v-stepper-content step="3">
          <MtrInfoStep
            @rewindStep="backStep"
            @saveStep="nextStep"
            :mtr="mtr"
          />
        </v-stepper-content>
        <!-- /step 3 -->

        <!-- step 4 -->
        <v-stepper-step
          step="4"
          :complete="activeStep > 4"
          :color="activeStep > 4 ? 'success' : 'primary'"
        >
          Finalizar
        </v-stepper-step>

        <v-stepper-content step="4">
          <MtrResumeStep
            :loadCreateMtr="loadCreateMtr"
            v-bind="formData"
            @rewindStep="backStep"
            @goToStep="editStep"
            @saveStep="saveForm"
          />
        </v-stepper-content>
        <!-- /step 4 -->
      </v-stepper>

      <v-snackbar
        v-model="errorSnackbar"
        color="red"
        :multi-line="true"
      >
        {{ errorMessage }}
        <v-btn
          @click="errorSnackbar = false"
          dark
          flat
        >
          Fechar
        </v-btn>
      </v-snackbar>
    </section>
  </in-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import InLayout from '@/layouts/InLayout.vue';
import MtrTransportAndDestinationStep from '@/components/mtr/MtrTransportAndDestinationStep.vue';
import MtrResidueStep from '@/components/mtr/MtrResidueStep.vue';
import MtrInfoStep from '@/components/mtr/MtrInfoStep.vue';
import MtrResumeStep from '@/components/mtr/MtrResumeStep.vue';
import eventBus from '@/utils/eventBus';
import { omit } from 'lodash';
import mtrService from '../../services/mtr-service';

export default {
  components: {
    InLayout,
    MtrTransportAndDestinationStep,
    MtrInfoStep,
    MtrResidueStep,
    MtrResumeStep,
  },
  data() {
    return {
      residuesFromModel: [],
      activeStep: 1,
      activeStatus: 'all',
      formData: {},
      errorSnackbar: false,
      errorMessage: '',
      loadCreateMtr: false,
    };
  },
  props: {
    id: {
      default: null,
    },
  },
  mounted() {
    if (this.id !== null) {
      this.$store.dispatch('mtr/fetchMtr', this.id)
        .then(() => {
          this.formData = { ...this.mtr };
        });
    }
  },
  methods: {
    setResiduesFromModel(payload) {
      this.residuesFromModel = payload;
    },
    backStep() {
      if (this.activeStep <= 1) {
        this.$router.replace('/');
        this.formData = {};
      }
      this.activeStep = this.activeStep - 1;
    },
    nextStep(data) {
      this.activeStep = this.activeStep + 1;
      _.extend(this.formData, data);
    },
    saveForm() {
      this.loadCreateMtr = true;
      try {
        const mtrResidues = this.formData.residues.map((mtrResidue) => {
          const residueId = _.get(mtrResidue, 'residue.id');
          return { ...mtrResidue, residue: residueId };
        });
        if (this.id === null) {
          this.createMtr({ ...this.formData, residues: mtrResidues }, 'createMtr');
        } else {
          this.updateMtr(1, omit(this.formData, ['residues']), 'updateMtr');
        }
        // this.$router.push('/gerador');
      } catch (error) {
        this.errorMessage = error.message;
        this.errorSnackbar = true;
      }
    },
    createMtr(payload) {
      mtrService.create(payload)
        .then(() => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'MTR salvo com sucesso!',
            messageIcon: 'mdi-check',
          });
          eventBus.$emit('mtrCreated', true);
          this.$router.push('/gerador');
        })
        .catch((err) => {
          eventBus.$emit('mtrCreated', false);
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
          this.$router.push('/gerador');
        })
        .finally(() => {
          this.loadCreateMtr = false;
        });
    },
    editStep(value) {
      this.activeStep = value;
    },
  },
  computed: {
    ...mapGetters({
      authenticatedUser: 'auth/currentUser',
      mtr: 'mtr/selectedMtr',
    }),
  },
};
</script>
