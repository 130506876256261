var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "form", staticClass: "mb-4", attrs: { value: _vm.valid } },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-lg": "" } },
        [
          _c("header", [
            _c("h2", { staticClass: "title" }, [
              _vm._v("Responsável pela emissão"),
            ]),
          ]),
          _c(
            "v-layout",
            { attrs: { wrap: "", "align-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { md5: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Nome do responsável",
                      rules: [_vm.validationRules.required],
                      required: "",
                    },
                    model: {
                      value: _vm.responsibleName,
                      callback: function ($$v) {
                        _vm.responsibleName = $$v
                      },
                      expression: "responsibleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { md4: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Cargo",
                      rules: [_vm.validationRules.required],
                      required: "",
                    },
                    model: {
                      value: _vm.responsibleRole,
                      callback: function ($$v) {
                        _vm.responsibleRole = $$v
                      },
                      expression: "responsibleRole",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("header", [
            _c("h2", { staticClass: "title" }, [_vm._v("Observações")]),
          ]),
          _c(
            "v-layout",
            { attrs: { wrap: "", "align-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { md9: "" } },
                [
                  _c("v-textarea", {
                    attrs: { "single-line": "", box: "" },
                    model: {
                      value: _vm.comments,
                      callback: function ($$v) {
                        _vm.comments = $$v
                      },
                      expression: "comments",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mb-3", attrs: { light: "" } }),
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { flat: "" },
                  on: {
                    click: function ($event) {
                      return _vm.rewindStep()
                    },
                  },
                },
                [_vm._v("\n        Voltar\n      ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: _vm.hasEmpty },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.saveStep()
                    },
                  },
                },
                [
                  _vm._v("\n        Continuar\n        "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-arrow-right"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }