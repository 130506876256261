<template>
  <v-layout v-if="person">
    <v-flex xs12>
      <h3 class="mb-2">{{personType}}</h3>

      <v-flex class="mb-3">
        <v-list class="grey lighten-4">
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                Nome
              </v-list-tile-sub-title>
              <v-list-tile-title>
                {{ person.name }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                {{ person.cpf_cnpj.length > 11 ? 'CNPJ' : 'CPF' }}
              </v-list-tile-sub-title>
              <v-list-tile-title>
                {{ formatCpfCnpj(person.cpf_cnpj)}}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                Endereço
              </v-list-tile-sub-title>
              <v-list-tile-title>
                {{address}}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                Fone
              </v-list-tile-sub-title>
              <v-list-tile-title>
                {{phone}}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

        </v-list>
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import formatters from '@/utils/formatters';

export default {
  name: 'MtrPersonInfo',
  props: ['personType', 'person'],
  components: {},
  data() {
    return {
      formatCpfCnpj,
      formatters,
    };
  },
  computed: {
    address() {
      const a = this.person.address;
      let address = '';
      address += `${a.street}, ${a.number}, `;
      address += a.complement ? `${a.complement}, ` : '';
      address += `${a.neighborhood}, ${a.city.name}, ${a.federal_unit.name}`;
      return address;
    },
    phone() {
      return this.formatters.filters.phone(this.person.cellphone);
    },
  },
};
</script>
