var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("p", { staticClass: "mb-5 grey--text" }, [
        _vm._v(
          "\n    Confira se todas as informações abaixo estão corretas\n    e clique no botão para finalizar o cadastro deste MTR.\n  "
        ),
      ]),
      _vm.generator
        ? _c(
            "section",
            { staticClass: "mb-4" },
            [
              _c("header", [
                _c("h2", { staticClass: "title" }, [_vm._v("Gerador")]),
              ]),
              _c("v-layout", { attrs: { column: "" } }, [
                _c("strong", { staticClass: "subheading" }, [
                  _vm._v(_vm._s(_vm._.get(_vm.generator, "name"))),
                ]),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.generator.cpf_cnpj.length === 14 ? "CNPJ" : "CPF"
                      ) +
                      ": \n        "
                  ),
                  _c("strong", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.formatCpfCnpj(_vm.generator.cpf_cnpj)) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.transporter || _vm.receiver
        ? _c(
            "section",
            [
              _c(
                "v-subheader",
                { staticClass: "pl-0" },
                [
                  _vm._v("\n      Transporte e Destino\n\n      "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "", flat: "" },
                      on: {
                        click: function ($event) {
                          return _vm.goToStep(1)
                        },
                      },
                    },
                    [_vm._v("\n        Alterar\n      ")]
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-4" }),
              _vm.hasTempStorager
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { md5: "", lg4: "" } },
                        [
                          _c("h2", { staticClass: "title" }, [
                            _vm._v("Armazenamento temporário"),
                          ]),
                          _c(
                            "v-card",
                            {
                              staticClass: "mb-4",
                              attrs: { color: "grey lighten-3", flat: "" },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "align-center": "" } },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v("mdi-garage-open"),
                                      ]),
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "text",
                                          attrs: { column: "" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._.get(
                                                  _vm.tempStorager,
                                                  "name"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "grey--text" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.tempStorager.cpf_cnpj
                                                      .length === 14
                                                      ? "CNPJ"
                                                      : "CPF"
                                                  ) +
                                                  ": \n                  " +
                                                  _vm._s(
                                                    _vm.formatCpfCnpj(
                                                      _vm.tempStorager.cpf_cnpj
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                { attrs: { row: "", "align-center": "" } },
                [
                  _vm.transporter
                    ? _c(
                        "v-flex",
                        { attrs: { md5: "", lg4: "" } },
                        [
                          _c("h2", { staticClass: "title" }, [
                            _vm._v("Transportador"),
                          ]),
                          _c(
                            "v-card",
                            { staticClass: "mb-4", attrs: { color: "white" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "align-center": "" } },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v("mdi-truck-delivery"),
                                      ]),
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "text",
                                          attrs: { column: "" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.transporter.name) +
                                              "\n                "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "grey--text" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.transporter.cpf_cnpj
                                                      .length === 14
                                                      ? "CNPJ"
                                                      : "CPF"
                                                  ) +
                                                  ": \n                  " +
                                                  _vm._s(
                                                    _vm.formatCpfCnpj(
                                                      _vm.transporter.cpf_cnpj
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    {
                      staticClass: "text-md-center",
                      attrs: { md2: "", lg4: "" },
                    },
                    [
                      _c("img", {
                        staticClass: "my-3 transport-arrow",
                        attrs: {
                          src: require("@/assets/transport-arrow.svg"),
                          alt: "Ícone de Seta",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { md5: "", lg4: "" } },
                    [
                      _c("h2", { staticClass: "title" }, [
                        _vm._v("Destinador"),
                      ]),
                      _c(
                        "v-card",
                        { staticClass: "mb-4", attrs: { color: "white" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-layout",
                                { attrs: { "align-center": "" } },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v("mdi-delete-variant"),
                                  ]),
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "text",
                                      attrs: { column: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._.get(_vm.receiver, "name")
                                          ) +
                                          "\n                "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "grey--text" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.receiver.cpf_cnpj.length ===
                                                  14
                                                  ? "CNPJ"
                                                  : "CPF"
                                              ) +
                                              ": \n                  " +
                                              _vm._s(
                                                _vm.formatCpfCnpj(
                                                  _vm.receiver.cpf_cnpj
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.residues
        ? _c(
            "section",
            [
              _c(
                "v-subheader",
                { staticClass: "pl-0" },
                [
                  _vm._v("\n      Resíduos\n\n      "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "", flat: "" },
                      on: {
                        click: function ($event) {
                          return _vm.goToStep(2)
                        },
                      },
                    },
                    [_vm._v("\n        Alterar\n      ")]
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-3" }),
              _c(
                "v-layout",
                { attrs: { column: "" } },
                _vm._l(_vm.residues, function (mtrResidue, index) {
                  return _c(
                    "v-card",
                    {
                      key: index,
                      staticClass: "item mb-3",
                      attrs: { color: "grey lighten-4" },
                    },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "white", flat: "" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "mr-3",
                              attrs: { label: "", small: "" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._.get(mtrResidue, "residue.code")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _c("h2", { staticClass: "subheading" }, [
                            _vm._v(
                              _vm._s(_vm._.get(mtrResidue, "residue.name"))
                            ),
                          ]),
                          _c("v-spacer"),
                          _c("span", { staticClass: "subheading grey--text" }, [
                            _vm._v(
                              _vm._s(_vm._.get(mtrResidue, "physicalState"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider", { attrs: { light: "" } }),
                      _c(
                        "v-card-text",
                        { staticClass: "grey--text text--darken-2" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", "justify-space-around": "" } },
                            [
                              _c("v-flex", [
                                _vm._v(
                                  "\n              Classe:\n              "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._.get(mtrResidue, "residueClass")
                                    )
                                  ),
                                ]),
                              ]),
                              _c("v-flex", [
                                _vm._v(
                                  "\n              Quantidade Indicada:\n              "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm._.get(mtrResidue, "quantity")) +
                                      " (" +
                                      _vm._s(
                                        _vm._.get(mtrResidue, "measureUnity")
                                      ) +
                                      ")"
                                  ),
                                ]),
                              ]),
                              _c("v-flex", [
                                _vm._v(
                                  "\n              Acondicionamento:\n              "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm._.get(mtrResidue, "packaging"))
                                  ),
                                ]),
                              ]),
                              _c("v-flex", [
                                _vm._v(
                                  "\n              Tecnologia:\n              "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._.get(
                                        mtrResidue,
                                        "disposalTechnology"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.responsibleName && _vm.responsibleRole
        ? _c(
            "section",
            [
              _c(
                "v-subheader",
                { staticClass: "pl-0" },
                [
                  _vm._v("\n      Informações Adicionais\n\n      "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "", flat: "" },
                      on: {
                        click: function ($event) {
                          return _vm.goToStep(3)
                        },
                      },
                    },
                    [_vm._v("\n        Alterar\n      ")]
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-3" }),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("h2", { staticClass: "title" }, [
                    _vm._v("Responsável pela emissão"),
                  ]),
                  _c("v-layout", { attrs: { column: "" } }, [
                    _c(
                      "h3",
                      { staticClass: "subheading grey--text text--darken-2" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.responsibleName) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "grey--text" }, [
                      _vm._v("\n          Cargo: "),
                      _c("strong", [_vm._v(_vm._s(_vm.responsibleRole))]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm.comments
                ? _c("div", { staticClass: "mb-5" }, [
                    _c("h2", { staticClass: "title" }, [_vm._v("Observações")]),
                    _c("p", { staticClass: "grey--text" }, [
                      _vm._v("\n        " + _vm._s(_vm.comments) + "\n      "),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { "justify-center": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { large: "", flat: "" },
              on: {
                click: function ($event) {
                  return _vm.rewindStep()
                },
              },
            },
            [_vm._v("\n      Voltar\n    ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                color: "success",
                loading: _vm.loadCreateMtr,
                large: "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.saveStep()
                },
              },
            },
            [_vm._v("\n      Salvar\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }