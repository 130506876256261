var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("in-layout", [
    _c(
      "section",
      { attrs: { slot: "content" }, slot: "content" },
      [
        _c(
          "v-stepper",
          {
            attrs: { vertical: "" },
            model: {
              value: _vm.activeStep,
              callback: function ($$v) {
                _vm.activeStep = $$v
              },
              expression: "activeStep",
            },
          },
          [
            _c(
              "v-stepper-step",
              {
                attrs: {
                  step: "1",
                  complete: _vm.activeStep > 1,
                  color: _vm.activeStep > 1 ? "success" : "primary",
                },
              },
              [_vm._v("\n        Transporte e Destino\n      ")]
            ),
            _c(
              "v-stepper-content",
              { attrs: { step: "1" } },
              [
                _c("MtrTransportAndDestinationStep", {
                  attrs: { mtr: _vm.mtr },
                  on: {
                    rewindStep: _vm.backStep,
                    saveStep: _vm.nextStep,
                    residuesFromModel: _vm.setResiduesFromModel,
                  },
                }),
              ],
              1
            ),
            _c(
              "v-stepper-step",
              {
                attrs: {
                  step: "2",
                  complete: _vm.activeStep > 2,
                  color: _vm.activeStep > 2 ? "success" : "primary",
                },
              },
              [_vm._v("\n        Resíduos\n      ")]
            ),
            _c(
              "v-stepper-content",
              { attrs: { step: "2" } },
              [
                _c("MtrResidueStep", {
                  attrs: {
                    mtr: _vm.mtr,
                    residuesFromModel: _vm.residuesFromModel,
                  },
                  on: { rewindStep: _vm.backStep, saveStep: _vm.nextStep },
                }),
              ],
              1
            ),
            _c(
              "v-stepper-step",
              {
                attrs: {
                  step: "3",
                  complete: _vm.activeStep > 3,
                  color: _vm.activeStep > 3 ? "success" : "primary",
                },
              },
              [_vm._v("\n        Informções Adicionais\n      ")]
            ),
            _c(
              "v-stepper-content",
              { attrs: { step: "3" } },
              [
                _c("MtrInfoStep", {
                  attrs: { mtr: _vm.mtr },
                  on: { rewindStep: _vm.backStep, saveStep: _vm.nextStep },
                }),
              ],
              1
            ),
            _c(
              "v-stepper-step",
              {
                attrs: {
                  step: "4",
                  complete: _vm.activeStep > 4,
                  color: _vm.activeStep > 4 ? "success" : "primary",
                },
              },
              [_vm._v("\n        Finalizar\n      ")]
            ),
            _c(
              "v-stepper-content",
              { attrs: { step: "4" } },
              [
                _c(
                  "MtrResumeStep",
                  _vm._b(
                    {
                      attrs: { loadCreateMtr: _vm.loadCreateMtr },
                      on: {
                        rewindStep: _vm.backStep,
                        goToStep: _vm.editStep,
                        saveStep: _vm.saveForm,
                      },
                    },
                    "MtrResumeStep",
                    _vm.formData,
                    false
                  )
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-snackbar",
          {
            attrs: { color: "red", "multi-line": true },
            model: {
              value: _vm.errorSnackbar,
              callback: function ($$v) {
                _vm.errorSnackbar = $$v
              },
              expression: "errorSnackbar",
            },
          },
          [
            _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n      "),
            _c(
              "v-btn",
              {
                attrs: { dark: "", flat: "" },
                on: {
                  click: function ($event) {
                    _vm.errorSnackbar = false
                  },
                },
              },
              [_vm._v("\n        Fechar\n      ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }