var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.person
    ? _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("h3", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.personType)),
              ]),
              _c(
                "v-flex",
                { staticClass: "mb-3" },
                [
                  _c(
                    "v-list",
                    { staticClass: "grey lighten-4" },
                    [
                      _c(
                        "v-list-tile",
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-sub-title", [
                                _vm._v("\n              Nome\n            "),
                              ]),
                              _c("v-list-tile-title", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.person.name) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-sub-title", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.person.cpf_cnpj.length > 11
                                        ? "CNPJ"
                                        : "CPF"
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _c("v-list-tile-title", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formatCpfCnpj(_vm.person.cpf_cnpj)
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-sub-title", [
                                _vm._v(
                                  "\n              Endereço\n            "
                                ),
                              ]),
                              _c("v-list-tile-title", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.address) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-sub-title", [
                                _vm._v("\n              Fone\n            "),
                              ]),
                              _c("v-list-tile-title", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.phone) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }