var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "900" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { color: "primary", dark: "" } }, on),
                [_vm._v("\n      Selecionar Modelo\n    ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline green white--text",
              attrs: { "primary-title": "" },
            },
            [_vm._v("\n      Selecionar Modelo de MTR\n    ")]
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.mtrModels,
                              "item-text": "name",
                              "return-object": "",
                              "no-data-text": "Nenhum modelo encontrado",
                              label: "Buscar por nome ou descrição",
                              "prepend-icon": "mdi-feature-search-outline",
                              "max-width": "600",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(data.item.name) +
                                              " - " +
                                              _vm._s(data.item.description) +
                                              "\n                  "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.mtrModelSelected,
                              callback: function ($$v) {
                                _vm.mtrModelSelected = $$v
                              },
                              expression: "mtrModelSelected",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("mtr-person-info", {
                            attrs: {
                              personType: "Transportador",
                              person: _vm.mtrModelSelected.transporter,
                            },
                          }),
                          _c("mtr-person-info", {
                            attrs: {
                              personType: "Destinador",
                              person: _vm.mtrModelSelected.receiver,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        _vm._l(
                          _vm.mtrModelSelected.residues,
                          function (mtrResidue, index) {
                            return _c(
                              "v-card",
                              {
                                key: index,
                                staticClass: "item mb-3",
                                attrs: { color: "grey lighten-4" },
                              },
                              [
                                _c(
                                  "v-toolbar",
                                  { attrs: { color: "white", flat: "" } },
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "mr-3",
                                        attrs: { label: "", small: "" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(mtrResidue.residue.code) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _c("h2", { staticClass: "subheading" }, [
                                      _vm._v(_vm._s(mtrResidue.residue.name)),
                                    ]),
                                    _c("v-spacer"),
                                    _c(
                                      "span",
                                      { staticClass: "subheading grey--text" },
                                      [
                                        _vm._v(
                                          _vm._s(mtrResidue.physical_state)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-divider", { attrs: { light: "" } }),
                                _c(
                                  "v-card-text",
                                  { staticClass: "grey--text text--darken-2" },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          row: "",
                                          "justify-space-around": "",
                                        },
                                      },
                                      [
                                        _c("v-flex", [
                                          _vm._v(
                                            "\n                    Classe:\n                    "
                                          ),
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(mtrResidue.residue_class)
                                            ),
                                          ]),
                                        ]),
                                        _c("v-flex", [
                                          _vm._v(
                                            "\n                    Acondicionamento:\n                    "
                                          ),
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(mtrResidue.packaging)
                                            ),
                                          ]),
                                        ]),
                                        _c("v-flex", [
                                          _vm._v(
                                            "\n                    Tecnologia:\n                    "
                                          ),
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                mtrResidue.disposal_technology
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { flat: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("\n        Fechar\n      ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", flat: "" },
                  on: {
                    click: function ($event) {
                      return _vm.selectMtrModel()
                    },
                  },
                },
                [_vm._v("\n        Selecionar\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }