<template>
  <v-container>
    <p class="mb-5 grey--text">
      Confira se todas as informações abaixo estão corretas
      e clique no botão para finalizar o cadastro deste MTR.
    </p>

    <section class="mb-4" v-if="generator">
      <header>
        <h2 class="title">Gerador</h2>
      </header>

      <v-layout column>
        <strong class="subheading">{{_.get(generator, 'name')}}</strong>
        <span>
          {{ generator.cpf_cnpj.length === 14 ? 'CNPJ' : 'CPF' }}:&nbsp;
          <strong>
            {{ formatCpfCnpj(generator.cpf_cnpj) }}
          </strong>
        </span>
        <!-- <span>CNPJ:<strong> {{_.get(generator, 'cpfCnpj')}}</strong></span> -->
      </v-layout>
    </section>

    <section v-if="transporter || receiver">
      <v-subheader class="pl-0">
        Transporte e Destino

        <v-btn
          color="primary"
          small
          flat
          @click="goToStep(1)"
        >
          Alterar
        </v-btn>
      </v-subheader>

      <v-divider class="mb-4" />

      <v-layout v-if="hasTempStorager">
        <v-flex md5 lg4>
          <h2 class="title">Armazenamento temporário</h2>

          <v-card
            class="mb-4"
            color="grey lighten-3"
            flat
          >
            <v-card-text>
              <v-layout align-center>
                <v-icon class="mr-2">mdi-garage-open</v-icon>
                <v-layout class="text" column>
                  {{_.get(tempStorager, 'name')}}
                  <span class="grey--text">
                    {{ tempStorager.cpf_cnpj.length === 14 ? 'CNPJ' : 'CPF' }}:&nbsp;
                    {{ formatCpfCnpj(tempStorager.cpf_cnpj) }}
                  </span>
                </v-layout>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row align-center>
        <v-flex md5 lg4 v-if="transporter">
          <h2 class="title">Transportador</h2>

          <v-card
            class="mb-4"
            color="white"
          >
            <v-card-text>
              <v-layout align-center>
                <v-icon class="mr-2">mdi-truck-delivery</v-icon>
                <v-layout class="text" column>
                  {{transporter.name}}
                  <span class="grey--text">
                    {{ transporter.cpf_cnpj.length === 14 ? 'CNPJ' : 'CPF' }}:&nbsp;
                    {{ formatCpfCnpj(transporter.cpf_cnpj) }}
                  </span>
                </v-layout>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex class="text-md-center" md2 lg4>
          <img
            class="my-3 transport-arrow"
            src="@/assets/transport-arrow.svg"
            alt="Ícone de Seta"
          />
        </v-flex>

        <v-flex md5 lg4>
          <h2 class="title">Destinador</h2>

          <v-card
            class="mb-4"
            color="white"
          >
            <v-card-text>
              <v-layout align-center>
                <v-icon class="mr-2">mdi-delete-variant</v-icon>
                <v-layout class="text" column>
                  {{_.get(receiver, 'name')}}
                  <span class="grey--text">
                    {{ receiver.cpf_cnpj.length === 14 ? 'CNPJ' : 'CPF' }}:&nbsp;
                    {{ formatCpfCnpj(receiver.cpf_cnpj) }}
                  </span>
                </v-layout>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </section>

    <section v-if="residues">
      <v-subheader class="pl-0">
        Resíduos

        <v-btn
          color="primary"
          small
          flat
          @click="goToStep(2)"
        >
          Alterar
        </v-btn>
      </v-subheader>

      <v-divider class="mb-3" />

      <v-layout column>
        <v-card
          color="grey lighten-4"
          class="item mb-3"
          v-for="(mtrResidue, index) in residues"
          :key="index"
        >
          <v-toolbar color="white" flat>
            <v-chip
              class="mr-3"
              label
              small
            >
              {{_.get(mtrResidue, 'residue.code')}}
            </v-chip>
            <h2 class="subheading">{{_.get(mtrResidue, 'residue.name')}}</h2>

            <v-spacer />

            <span class="subheading grey--text">{{_.get(mtrResidue, 'physicalState')}}</span>
          </v-toolbar>

          <v-divider light />

          <v-card-text class="grey--text text--darken-2">
            <v-layout row justify-space-around>
              <v-flex>
                Classe:
                <strong>{{_.get(mtrResidue, 'residueClass')}}</strong>
              </v-flex>
              <v-flex>
                Quantidade Indicada:
                <strong>{{_.get(mtrResidue, 'quantity')}} ({{_.get(mtrResidue, 'measureUnity')}})</strong>
              </v-flex>
              <v-flex>
                Acondicionamento:
                <strong>{{_.get(mtrResidue, 'packaging')}}</strong>
              </v-flex>
              <v-flex>
                Tecnologia:
                <strong>{{_.get(mtrResidue, 'disposalTechnology')}}</strong>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <section v-if="responsibleName && responsibleRole">
      <v-subheader class="pl-0">
        Informações Adicionais

        <v-btn
          color="primary"
          small
          flat
          @click="goToStep(3)"
        >
          Alterar
        </v-btn>
      </v-subheader>

      <v-divider class="mb-3" />

      <div class="mb-4">
        <h2 class="title">Responsável pela emissão</h2>

        <v-layout column>
          <h3 class="subheading grey--text text--darken-2">
            {{responsibleName}}
          </h3>
          <span class="grey--text">
            Cargo: <strong>{{responsibleRole}}</strong>
          </span>
        </v-layout>
      </div>

      <div class="mb-5" v-if="comments">
        <h2 class="title">Observações</h2>

        <p class="grey--text">
          {{comments}}
        </p>
      </div>
    </section>

    <v-layout justify-center>
      <v-btn
        @click="rewindStep()"
        large
        flat
      >
        Voltar
      </v-btn>

      <v-btn
        color="success"
        :loading="loadCreateMtr"
        @click.stop="saveStep()"
        large
      >
        Salvar
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import formatCpfCnpj from '@/utils/formatCpfCnpj';

export default {
  name: 'MtrResumeStep',
  data() {
    return {
      formatCpfCnpj,
    };
  },
  methods: {
    rewindStep() {
      this.$emit('rewindStep');
    },
    saveStep() {
      this.$emit('saveStep');
    },
    goToStep(value) {
      this.$emit('goToStep', value);
    },
  },
  props: [
    'generator',
    'transporter',
    'tempStorager',
    'hasTempStorager',
    'receiver',
    'residues',
    'responsibleName',
    'responsibleRole',
    'comments',
    'loadCreateMtr',
  ],
};
</script>

<style scoped lang="scss">
.transport-arrow {
  max-width: 68px;
}
</style>
