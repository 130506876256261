<template>
  <v-form class="mb-4" ref="form" :value="valid">
    <v-container grid-list-lg>
      <header>
        <h2 class="title">Responsável pela emissão</h2>
      </header>

      <v-layout wrap align-center>
        <v-flex md5>
          <v-text-field
            label="Nome do responsável"
            v-model="responsibleName"
            :rules="[validationRules.required]"
            required
          />
        </v-flex>

        <v-flex md4>
          <v-text-field
            label="Cargo"
            v-model="responsibleRole"
            :rules="[validationRules.required]"
            required
          />
        </v-flex>
      </v-layout>

      <header>
        <h2 class="title">Observações</h2>
      </header>

      <v-layout wrap align-center>
        <v-flex md9>
          <v-textarea
            v-model="comments"
            single-line
            box
          />
        </v-flex>
      </v-layout>

      <v-divider class="mb-3" light />

      <v-layout justify-center>
        <v-btn
          @click="rewindStep()"
          flat
        >
          Voltar
        </v-btn>

        <v-btn
          color="primary"
          @click.stop="saveStep()"
          :disabled="hasEmpty"
        >
          Continuar
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import validationRules from '@/mixins/validation-rules';
import { isEmpty } from 'lodash';

export default {
  name: 'MtrInfoStep',
  props: {
    mtr: {
      default: {},
    },
  },
  data() {
    return {
      responsibleName: '',
      responsibleRole: '',
      comments: '',
      valid: true,
      validationRules,
    };
  },
  methods: {
    rewindStep() {
      this.infoData = {};
      this.$emit('rewindStep');
    },
    saveStep() {
      if (this.$refs.form.validate()) {
        this.$emit('saveStep', {
          generator: this.generator,
          generatorId: this.generator.id,
          responsibleName: this.responsibleName,
          responsibleRole: this.responsibleRole,
          comments: this.comments,
        });
      }
    },
  },
  computed: {
    generator() {
      const user = this.$store.getters['auth/currentUser'];
      return user.person;
    },
    hasEmpty() {
      return !this.responsibleName
          || !this.responsibleRole;
    },
  },
  watch: {
    mtr(val) {
      if (!isEmpty(val)) {
        this.responsibleName = val.responsibleName;
        this.responsibleRole = val.responsibleRole;
        this.comments = val.comments;
      }
    },
  },
};
</script>
