var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "form", staticClass: "mb-4", attrs: { value: _vm.valid } },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-lg": "" } },
        [
          _c("mtr-person-info", {
            attrs: {
              personType: "Gerador",
              person: this.$store.getters["auth/currentUser"].person,
            },
          }),
          _c("select-mtr-model-dialog", {
            on: { mtrModalSelected: _vm.mtrModalSelected },
          }),
          _c(
            "v-layout",
            [
              _c(
                "v-flex",
                { attrs: { md6: "" } },
                [
                  _c("h3", { staticClass: "mb-2" }, [
                    _vm._v("Tem armazenamento temporário?"),
                  ]),
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.tempStorageChoice,
                        callback: function ($$v) {
                          _vm.tempStorageChoice = $$v
                        },
                        expression: "tempStorageChoice",
                      },
                    },
                    [
                      _c("v-radio", { attrs: { label: "Não", value: false } }),
                      _c("v-radio", { attrs: { label: "Sim", value: true } }),
                    ],
                    1
                  ),
                  _vm.tempStorageChoice
                    ? _c("MtrPersonForm", {
                        attrs: {
                          title: "Informações de armazenamento temporário",
                          profile: "temporary_storage",
                          person: _vm.tempStorager,
                          description: `Caso utilize armazenamento temporário,
            informar quem é o armazenador.`,
                          "temp-storager": true,
                        },
                        on: {
                          "update:person": function ($event) {
                            _vm.tempStorager = $event
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            [
              _c(
                "v-flex",
                { attrs: { md6: "" } },
                [
                  _c("h3", { staticClass: "mb-2" }, [_vm._v("Transportador")]),
                  _c("MtrPersonForm", {
                    attrs: {
                      title: "Informações do transportador",
                      person: _vm.transporter,
                      transportedAt: _vm.transportedAt,
                      transportedBy: _vm.transportedBy,
                      transportLicensePlate: _vm.transportLicensePlate,
                      profile: "transporter",
                    },
                    on: {
                      "update:person": function ($event) {
                        _vm.transporter = $event
                      },
                      "update:transportedAt": function ($event) {
                        _vm.transportedAt = $event
                      },
                      "update:transported-at": function ($event) {
                        _vm.transportedAt = $event
                      },
                      "update:transportedBy": function ($event) {
                        _vm.transportedBy = $event
                      },
                      "update:transported-by": function ($event) {
                        _vm.transportedBy = $event
                      },
                      "update:transportLicensePlate": function ($event) {
                        _vm.transportLicensePlate = $event
                      },
                      "update:transport-license-plate": function ($event) {
                        _vm.transportLicensePlate = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { md6: "" } },
                [
                  _c("h3", { staticClass: "mb-2" }, [_vm._v("Destinador")]),
                  _c("MtrPersonForm", {
                    attrs: {
                      title: "Informações do destinador",
                      person: _vm.receiver,
                      profile: "receiver",
                    },
                    on: {
                      "update:person": function ($event) {
                        _vm.receiver = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { light: "", "my-4": "" } }),
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { flat: "" },
                  on: {
                    click: function ($event) {
                      return _vm.rewindStep()
                    },
                  },
                },
                [_vm._v("\n        Cancelar\n      ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.hasEmpty || _vm.tempStorageIsRequired,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.saveStep()
                    },
                  },
                },
                [
                  _vm._v("\n        Continuar\n        "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-arrow-right"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }