var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        {
          staticClass: "residue-list",
          attrs: { "align-center": "", column: "" },
        },
        [
          _c("div", [
            _vm.residues.length === 0
              ? _c("p", { staticClass: "grey--text" }, [
                  _vm._v("\n        Selecione os resíduos deste MTR\n      "),
                ])
              : _vm._e(),
            _vm.residues.length > 0
              ? _c("p", { staticClass: "grey--text" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.residues.length) + " resíduos adicionados."
                    ),
                  ]),
                  _vm._v(
                    "\n        Clique no item para editar ou remover.\n      "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._l(_vm.residues, function (mtrResidue, index) {
            return _c(
              "v-card",
              {
                key: index,
                staticClass: "item mb-3",
                attrs: { color: "grey lighten-4" },
              },
              [
                _c(
                  "v-toolbar",
                  { attrs: { color: "white", flat: "" } },
                  [
                    _c(
                      "v-chip",
                      { staticClass: "mr-3", attrs: { label: "", small: "" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(mtrResidue.residue.code) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c("h2", { staticClass: "subheading" }, [
                      _vm._v(_vm._s(mtrResidue.residue.name)),
                    ]),
                    _c("v-spacer"),
                    _c("span", { staticClass: "subheading grey--text" }, [
                      _vm._v(_vm._s(mtrResidue.physicalState)),
                    ]),
                    _c(
                      "v-menu",
                      { attrs: { "offset-y": "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { slot: "activator", fab: "", flat: "" },
                            slot: "activator",
                          },
                          [_c("v-icon", [_vm._v("mdi-dots-horizontal")])],
                          1
                        ),
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-tile",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(index, mtrResidue)
                                  },
                                },
                              },
                              [
                                _c("v-list-tile-title", [
                                  _vm._v("Editar resíduo"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-tile",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(index)
                                  },
                                },
                              },
                              [
                                _c("v-list-tile-title", [
                                  _vm._v("Remover resíduo"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider", { attrs: { light: "" } }),
                _c(
                  "v-card-text",
                  { staticClass: "grey--text text--darken-2" },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", "justify-space-around": "" } },
                      [
                        _c("v-flex", [
                          _vm._v("\n            Classe:\n            "),
                          _c("strong", [
                            _vm._v(_vm._s(mtrResidue.residueClass)),
                          ]),
                        ]),
                        _c("v-flex", [
                          _vm._v(
                            "\n            Quantidade Indicado:\n            "
                          ),
                          _c("strong", [
                            _vm._v(
                              _vm._s(mtrResidue.quantity) +
                                " (" +
                                _vm._s(mtrResidue.measureUnity) +
                                ")"
                            ),
                          ]),
                        ]),
                        _c("v-flex", [
                          _vm._v(
                            "\n            Acondicionamento:\n            "
                          ),
                          _c("strong", [_vm._v(_vm._s(mtrResidue.packaging))]),
                        ]),
                        _c("v-flex", [
                          _vm._v("\n            Tecnologia:\n            "),
                          _c("strong", [
                            _vm._v(_vm._s(mtrResidue.disposalTechnology)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px", persistent: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { slot: "activator", color: "primary", flat: "" },
                  slot: "activator",
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-plus-circle-outline"),
                  ]),
                  _vm._v("\n        Adicionar resíduo\n      "),
                ],
                1
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "success", dark: "", flat: "" } },
                        [
                          _c("span", { staticClass: "headline" }, [
                            _vm._v(_vm._s(_vm.dialogTitle)),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "", icon: "" },
                              on: { click: _vm.close },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        ref: "residueInput",
                                        attrs: {
                                          items: _vm.fetchedResidues,
                                          "item-text": "name",
                                          "return-object": "",
                                          loading: _vm.isFetchingResidues,
                                          "no-data-text":
                                            "Nenhum resíduo encontrado",
                                          "hide-no-data":
                                            !_vm.residuesSearch ||
                                            _vm.isUserTyping,
                                          "no-filter": "",
                                          "search-input": _vm.residuesSearch,
                                          label:
                                            "Buscar resíduo por: Código/Palavra",
                                          rules: [_vm.rules.required],
                                          "prepend-icon":
                                            "mdi-feature-search-outline",
                                          "menu-props": { maxWidth: "600" },
                                        },
                                        on: {
                                          "update:searchInput": function (
                                            $event
                                          ) {
                                            _vm.residuesSearch = $event
                                          },
                                          "update:search-input": function (
                                            $event
                                          ) {
                                            _vm.residuesSearch = $event
                                          },
                                          input: function ($event) {
                                            return _vm.onChangeSelectedResidue()
                                          },
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append-outer",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      top: "",
                                                      "max-width": "300",
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g({}, on),
                                                              [
                                                                _vm._v(
                                                                  "\n                            mdi-information-outline\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                          Listagem de Código e descrição dos resíduos de acordo\n                           com a Lista Brasileira de Resíduos Sólidos do IBAMA\n                          (Instrução Normativa Nº 13, de 18 de dezembro de 2012)\n                        "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c("v-list-tile-title", [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            data.item.code
                                                          ) +
                                                          " - " +
                                                          _vm._s(
                                                            data.item.name
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.editedItem.residue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "residue",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.residue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md4: "" } },
                                    [
                                      _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value: _vm.money,
                                            expression: "money",
                                          },
                                        ],
                                        ref: "quantityField",
                                        attrs: {
                                          label: "Quantidade",
                                          rules: [
                                            _vm.rules.required,
                                            _vm.rules.number,
                                            _vm.rules.minValue,
                                          ],
                                          min: "0",
                                        },
                                        model: {
                                          value: _vm.editedItem.quantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "quantity",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.quantity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md4: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.measureUnityNames,
                                          label: "Unidade",
                                          rules: [_vm.rules.required],
                                        },
                                        model: {
                                          value: _vm.editedItem.measureUnity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "measureUnity",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.measureUnity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.hasDensity
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "", md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              label: "Densidade",
                                              rules: [
                                                _vm.rules.required,
                                                _vm.rules.number,
                                              ],
                                              suffix: _vm.densitySufix(
                                                _vm.editedItem.measureUnity
                                              ),
                                            },
                                            model: {
                                              value: _vm.editedItem.density,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "density",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "editedItem.density",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md6: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.classes,
                                          label: "Classes",
                                          rules: [_vm.rules.required],
                                          placeholder:
                                            _vm.classFieldPlaceholder,
                                          disabled: _vm.isEmpty(
                                            _vm.editedItem.residue
                                          ),
                                        },
                                        model: {
                                          value: _vm.editedItem.residueClass,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "residueClass",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.residueClass",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md6: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.physicalStates,
                                          "item-text": "name",
                                          "item-id": "id",
                                          label: "Estado físico",
                                          rules: [_vm.rules.required],
                                        },
                                        model: {
                                          value: _vm.editedItem.physicalState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "physicalState",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editedItem.physicalState",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md6: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.packagings,
                                          label: "Acondicionamento",
                                          disabled:
                                            !_vm.editedItem.physicalState,
                                          placeholder:
                                            _vm.hasPhysicalStatePlaceHolder,
                                          rules: [_vm.rules.required],
                                        },
                                        model: {
                                          value: _vm.editedItem.packaging,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "packaging",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.packaging",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md6: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.technologiesNames,
                                          label: "Tecnologia",
                                          rules: [_vm.rules.required],
                                        },
                                        model: {
                                          value:
                                            _vm.editedItem.disposalTechnology,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "disposalTechnology",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editedItem.disposalTechnology",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "grey", flat: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.close.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                Cancelar\n              "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.save.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("\n              Confirmar\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("v-divider", { staticClass: "my-3", attrs: { light: "" } }),
      _c(
        "v-layout",
        { attrs: { "justify-center": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { flat: "" },
              on: {
                click: function ($event) {
                  return _vm.rewindStep()
                },
              },
            },
            [_vm._v("\n      Voltar\n    ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", disabled: _vm.hasEmpty },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.saveStep()
                },
              },
            },
            [
              _vm._v("\n      Continuar\n      "),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v("mdi-arrow-right"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }